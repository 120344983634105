/**
 * Specific values for the DEV environment.
 */
 const api_endpoint = 'https://api.dev.newjobapplications.com';

 const environment = {
   env: 'dev',
   number_of_jobs: 20,
   pushnami_id: '5eb582d65cf5e10012d938c7',
   recommend_search_api: `${api_endpoint}/reco/search`,
   recommend_api: `${api_endpoint}/reco/related/query/to/query/`,
   related_api: `${api_endpoint}/reco/search/by/job/`,
   subscriber_api: `${api_endpoint}/subscription`,
   talroo_search_api: `${api_endpoint}/reco/search`,
   api_key: 'DUFhslfZeP4NS0n8ToPWA7wsDPaRXgV47RJLCO0a',
   tenant_id: '6ba75qr8qldgqsjr5imnq0dpqu',
   default_api_format: 'json',
   default_api_link: 1,
   default_api_logo: 1,
   j2c_web_api_endpoint: 'https://0nivjwo2nf.execute-api.us-east-1.amazonaws.com/dev/proxies',
   j2c_web_api_bearer_token: 'FEHfiZFOincvDBx5jMKYdump0xdw00OtZHKaicLk9NeWhS2QK67fXO9nbMzUAVEi',
   adSense: {},
   talroo_terms_page: 'https://www.talroo.com/terms/?utm_source=dev.jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=terms',
   talroo_privacy_page: 'https://www.talroo.com/privacy/?utm_source=dev.jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=privacy',
   talroo_ccpa_dns_page: 'https://www.talroo.com/ccpa-dns/?utm_source=dev.jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=dnsmpi',
   talroo_ccpa_privacy_page: 'https://www.talroo.com/ccpa-privacy/?utm_source=dev.jobsinyourtown.com&utm_campaign=jobs2careers&utm_content=ccpa-privacy',
   job_display: {
     use_pop: true,
     ad_cid: 'Y3VzdG9tZXJfaWQ6MTk0',
     ad_id: '17140d91-27fe-401f-9281-80268909ecf7',
   },
 };
 
 export default environment;
 